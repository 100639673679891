import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="G_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Our mission is to provide our customers with a seamless shopping experience, offering high-quality products at competitive prices. With a focus on customer satisfaction, we strive to exceed expectations and build long-lasting relationships.</p>
        <button type="button" className="custom__button">Know More</button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Founded in 2023, we started as a small online retailer with a big vision. Through hard work and dedication, we have grown into a trusted e-commerce destination, serving customers around the world with an ever-expanding product selection and top-notch customer service.</p>
        <button type="button" className="custom__button">Know More</button>
      </div>
    </div>
  </div> 
);

export default AboutUs;
