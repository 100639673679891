import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Chef's word" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">Shop till you drop, without ever leaving your couch.</p>
        </div>
        <p className="p__opensans"> E-commerce is not just about buying and selling, it's about connecting with people and providing them with a personalized experience. It's the art of creating a seamless journey from the first click to the final purchase. </p>
      </div>

      <div className="app__chef-sign">
        <p>Thabang Musekgwa</p>
        <p className="p__opensans">Developer & Founder</p>
        <img src={images.tee} alt="sign_image" />
      </div>
    </div>
  </div>  
);

export default Chef;
