import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import gallery05 from '../assets/gallery05.jpg';
import gallery06 from '../assets/gallery06.jpg';
import gallery07 from '../assets/gallery07.jpg';
import gallery08 from '../assets/gallery08.jpg';
import gallery09 from '../assets/gallery09.jpg';
import gallery10 from '../assets/gallery10.jpg';
import gallery11 from '../assets/gallery11.jpg';
import gallery12 from '../assets/gallery12.jpg';
import gallery13 from '../assets/gallery13.jpg';
import gallery14 from '../assets/gallery14.jpg';
import gallery15 from '../assets/gallery15.jpg';

import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import menus from '../assets/menus.jpg';

import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import tee from '../assets/tee.png';

import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';

export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  knife,
  logo,
  menu,
  menus,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  tee,
  quote,
  gericht,
};
